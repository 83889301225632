<template>
  <span
    class="whitespace-nowrap flex text-sm text-center rounded w-min bg-gray-100"
    :class="{
      'border-solid rounded-xl': isRounded,
      'w-full overflow-hidden text-ellipsis': truncate,
      'bg-white border': props.componentStyle === 'attachDocumentStyle',
      'm-1': !noMargin,
      'px-2 py-0-5': !noPadding
    }"
  >
    <slot />
    <div
      v-if="removable"
      data-cy="remove-badge"
      @click.stop="$emit('click:remove')"
      :class="`${
        isRounded
          ? 'relative w-5 h-5 border-solid rounded-full bg-gray-300 ml-1.5'
          : ''
      } flex flex-auto flex-row-reverse place-self-center font-normal pl-2 cursor-pointer`"
    >
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        xmlns="http://www.w3.org/2000/svg"
        :class="`${isRounded ? 'absolute inset-0 m-auto' : ''}`"
        :fill="`${isRounded ? '#ffffff' : 'none'}`"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.23966 8.7934C9.63019 8.40288 9.63019 7.76971 9.23966 7.37919L6.41119 4.55071L9.23959 1.7223C9.63011 1.33178 9.63011 0.698615 9.23959 0.308091C8.84907 -0.0824335 8.2159 -0.0824339 7.82538 0.308091L4.99697 3.1365L2.16859 0.308119C1.77807 -0.0824052 1.14491 -0.0824052 0.754381 0.308119C0.363857 0.698643 0.363856 1.33181 0.754381 1.72233L3.58276 4.55071L0.75431 7.37916C0.363786 7.76968 0.363786 8.40285 0.75431 8.79337C1.14483 9.1839 1.778 9.1839 2.16852 8.79337L4.99697 5.96492L7.82545 8.7934C8.21597 9.18392 8.84914 9.18392 9.23966 8.7934Z"
          :fill="`${!isRounded ? '#868D9C' : '#FFFFF'}`"
        />
      </svg>
    </div>
  </span>
</template>
<script setup lang="ts">
const props = defineProps({
  removable: {
    type: Boolean,
    default: false
  },
  isRounded: {
    type: Boolean,
    default: false
  },
  componentStyle: {
    type: String,
    default: ""
  },
  truncate: {
    type: Boolean,
    default: false
  },
  noMargin: {
    type: Boolean,
    default: false
  },
  noPadding: {
    type: Boolean,
    default: false
  }
});
</script>

<style scoped>
span {
  color: #868d9c;
}
</style>
